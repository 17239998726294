import Popper from 'popper.js';
import Data from '../../mdb/dom/data';
import EventHandler from '../../mdb/dom/event-handler';
import Manipulator from '../../mdb/dom/manipulator';
import SelectorEngine from '../../mdb/dom/selector-engine';
import { typeCheckConfig, getjQuery, getUID, element } from '../../mdb/util/index';
import Input from '../../free/input';
import SelectOption from './select-option';
import SelectionModel from './selection-model';
import { ESCAPE, ENTER, DOWN_ARROW, UP_ARROW, HOME, END } from '../../mdb/util/keycodes';

const Default = {
  clearButton: false,
  disabled: false,
  displayedLabels: 5,
  multiple: false,
  optionsSelectedLabel: 'options selected',
  optionHeight: 48,
  visibleOptions: 5,
  filter: false,
  filterDebounce: 300,
  noResultText: 'No results',
  validation: false,
  validFeedback: 'Valid',
  invalidFeedback: 'Invalid',
  placeholder: '',
};
const DefaultType = {
  clearButton: 'boolean',
  disabled: 'boolean',
  displayedLabels: 'number',
  multiple: 'boolean',
  optionsSelectedLabel: 'string',
  optionHeight: 'number',
  visibleOptions: 'number',
  filter: 'boolean',
  filterDebounce: 'number',
  noResultText: 'string',
  validation: 'boolean',
  validFeedback: 'string',
  invalidFeedback: 'string',
  placeholder: '',
};

const NAME = 'select';
const DATA_KEY = 'mdb.select';
const EVENT_KEY = `.${DATA_KEY}`;
// const DATA_API_KEY = '.data-api';
// const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`;
const EVENT_CLOSE = `close${EVENT_KEY}`;
const EVENT_OPEN = `open${EVENT_KEY}`;
const EVENT_SELECT = `select${EVENT_KEY}`;
const EVENT_DESELECT = `deselect${EVENT_KEY}`;

const SELECTOR_SELECT = '.select';

class Select {
  constructor(element, config) {
    this._element = element;
    this._config = this._getConfig(config);
    this._optionsToRender = this._getOptionsToRender(element);

    // optionsToRender may contain option groups and nested options, in this case
    // we need a list of plain options to manage selections and keyboard navigation
    this._optionsList = this._getOptionsList(this._optionsToRender);
    this._filteredOptionsList = null;
    this._filteredOptions = null;
    this._selectionModel = new SelectionModel(this._config.multiple);

    this._activeOptionIndex = -1;
    this._activeOption = null;

    this._wrapperId = getUID('select-wrapper-');
    this._dropdownContainerId = getUID('select-dropdown-container-');
    this._debounceTimeoutId = null;

    this._dropdownHeight = this._config.optionHeight * this._config.visibleOptions;

    this._popper = null;
    this._input = null;
    this._label = SelectorEngine.next(this._element, '.select-label')[0];
    this._customContent = SelectorEngine.next(element, '.select-custom-content')[0];

    this._init();

    if (this._config.multiple) {
      this._selectAllOption = this._createSelectAllOption();
    }

    this._dropdownTemplate = this._getDropdownTemplate();

    this._mutationObserver = null;
    this._dropdownTemplateNeedsUpdate = false;
    this._isOpen = false;
    this._addMutationObserver();

    if (this._element) {
      Data.setData(element, DATA_KEY, this);
    }
  }

  static get NAME() {
    return NAME;
  }

  get wrapper() {
    return SelectorEngine.findOne(`#${this._wrapperId}`);
  }

  get input() {
    return SelectorEngine.findOne('.select-input', this.wrapper);
  }

  get filterInput() {
    return SelectorEngine.findOne('.select-filter-input', this.dropdownContainer);
  }

  get inputWidth() {
    return this.input.offsetWidth;
  }

  get dropdownContainer() {
    return SelectorEngine.findOne(`#${this._dropdownContainerId}`);
  }

  get dropdown() {
    return SelectorEngine.findOne('.select-dropdown', this.dropdownContainer);
  }

  get optionsList() {
    return SelectorEngine.findOne('.select-options-list');
  }

  get optionsWrapper() {
    return SelectorEngine.findOne('.select-options-wrapper', this.dropdownContainer);
  }

  get selections() {
    return this._selectionModel.selections;
  }

  get clearButton() {
    return SelectorEngine.findOne('.select-clear-btn', this.wrapper);
  }

  get activeIndex() {
    const firstSelected = this._config.multiple
      ? this._selectionModel.selection
      : this._selectionModel.selections[0];

    let index;

    if (firstSelected) {
      index = this.options.findIndex((option) => option.id === firstSelected.id);
    } else {
      index = -1;
    }

    return index;
  }

  get options() {
    return this._filteredOptionsList ? this._filteredOptionsList : this._optionsList;
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element);

    config = {
      ...Default,
      ...dataAttributes,
      ...config,
    };

    if (this._element.hasAttribute('multiple')) {
      config.multiple = true;
    }

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  _getOptions(options) {
    return Array.from(options).map((option) => this._createOptionObject(option));
  }

  _getOptionsToRender(select) {
    const options = [];

    const nodes = select.childNodes;

    nodes.forEach((node) => {
      if (node.nodeName === 'OPTGROUP') {
        const optionGroup = {
          id: getUID('group-'),
          label: node.label,
          disabled: node.hasAttribute('disabled'),
          options: [],
        };
        const groupOptions = node.childNodes;
        groupOptions.forEach((option) => {
          if (option.nodeName === 'OPTION') {
            optionGroup.options.push(this._createOptionObject(option, optionGroup));
          }
        });
        options.push(optionGroup);
      } else if (node.nodeName === 'OPTION') {
        options.push(this._createOptionObject(node));
      }
    });
    return options;
  }

  _getOptionsList(optionsToRender) {
    const hasOptionGroup = SelectorEngine.findOne('optgroup', this._element);

    if (!hasOptionGroup) {
      return optionsToRender;
    }

    const options = [];

    optionsToRender.forEach((option) => {
      const isOptionGroup = option.hasOwnProperty('options');
      if (isOptionGroup) {
        option.options.forEach((nestedOption) => {
          options.push(nestedOption);
        });
      } else {
        options.push(option);
      }
    });

    return options;
  }

  _createOptionObject(nativeOption, group = {}) {
    const id = getUID('option-');
    const groupId = group.id ? group.id : null;
    const groupDisabled = group.disabled ? group.disabled : false;
    const selected = nativeOption.selected;
    const disabled = nativeOption.hasAttribute('disabled') || groupDisabled;
    const multiple = this._config.multiple;
    const value = nativeOption.value;
    const label = nativeOption.label;
    const secondaryText = Manipulator.getDataAttribute(nativeOption, 'secondaryText');
    const icon = Manipulator.getDataAttribute(nativeOption, 'icon');
    return new SelectOption(
      id,
      nativeOption,
      multiple,
      value,
      label,
      selected,
      disabled,
      secondaryText,
      groupId,
      icon
    );
  }

  _getNavigationOptions() {
    return this._config.multiple ? [this._selectAllOption, ...this.options] : this.options;
  }

  _init() {
    this._renderMaterialWrapper();
    this._setDefaultSelections();
    this._updateInputValue();
    this._updateLabelPosition();
    this._updateClearButtonVisibility();

    this._bindComponentEvents();
  }

  _bindComponentEvents() {
    this._listenToComponentKeydown();
    this._listenToInputClick();
    this._listenToClearBtnClick();
  }

  _setDefaultSelections() {
    this.options.forEach((option) => {
      if (option.selected) {
        this._selectionModel.select(option);
      }
    });

    const firstActiveOption = this._config.multiple
      ? this._selectionModel.selections[0]
      : this._selectionModel.selection;

    this._activeOption = firstActiveOption;
    this._activeOptionIndex = this.options.findIndex((option) => option === firstActiveOption);
  }

  _listenToComponentKeydown() {
    EventHandler.on(this.wrapper, 'keydown', (event) => {
      if (this._isOpen && !this._config.filter) {
        this._handleOpenKeydown(event);
      } else {
        this._handleClosedKeydown(event);
      }
    });
  }

  _handleOpenKeydown(event) {
    const key = event.keyCode;
    const isCloseKey = key === ESCAPE || (key === UP_ARROW && event.altKey);

    if (isCloseKey) {
      this.close();
      this.input.focus();
      return;
    }

    switch (key) {
      case DOWN_ARROW:
        this._setNextOptionActive();
        this._scrollToOption(this._activeOption);
        break;
      case UP_ARROW:
        this._setPreviousOptionActive();
        this._scrollToOption(this._activeOption);
        break;
      case HOME:
        this._setFirstOptionActive();
        this._scrollToOption(this._activeOption);
        break;
      case END:
        this._setLastOptionActive();
        this._scrollToOption(this._activeOption);
        break;
      case ENTER:
        if (this._config.multiple && this._activeOptionIndex === 0) {
          this._handleSelectAll();
        } else {
          this._handleSelection(this._activeOption);
        }
        return;
      default:
        return;
    }

    event.preventDefault();
  }

  _handleClosedKeydown(event) {
    event.preventDefault();
    const key = event.keyCode;
    const isOpenKey =
      key === ENTER ||
      (key === DOWN_ARROW && event.altKey) ||
      (key === DOWN_ARROW && this._config.multiple);

    if (isOpenKey) {
      this.open();
    }

    if (!this._config.multiple) {
      switch (key) {
        case DOWN_ARROW:
          this._setNextOptionActive();
          this._handleSelection(this._activeOption);
          break;
        case UP_ARROW:
          this._setPreviousOptionActive();
          this._handleSelection(this._activeOption);
          break;
        case HOME:
          this._setFirstOptionActive();
          this._handleSelection(this._activeOption);
          break;
        case END:
          this._setLastOptionActive();
          this._handleSelection(this._activeOption);
          break;
        default:
          return;
      }
    }
  }

  _scrollToOption(option) {
    if (!option) {
      return;
    }

    let optionIndex;

    if (this._config.multiple) {
      optionIndex = this.options.indexOf(option) + 1;
    } else {
      optionIndex = this.options.indexOf(option);
    }

    const groupsNumber = this._getNumberOfGroupsBeforeOption(optionIndex);

    const scrollToIndex = optionIndex + groupsNumber;

    const list = this.optionsWrapper;
    const listHeight = list.offsetHeight;
    const optionHeight = this._config.optionHeight;
    const scrollTop = list.scrollTop;

    if (optionIndex > -1) {
      const optionOffset = scrollToIndex * optionHeight;
      const isBelow = optionOffset + optionHeight > scrollTop + listHeight;
      const isAbove = optionOffset < scrollTop;

      if (isAbove) {
        list.scrollTop = optionOffset;
      } else if (isBelow) {
        list.scrollTop = optionOffset - listHeight + optionHeight;
      } else {
        list.scrollTop = scrollTop;
      }
    }
  }

  _getNumberOfGroupsBeforeOption(optionIndex) {
    const optionsList = this.options;
    const groupsList = this._optionsToRender;
    const index = this._config.multiple ? optionIndex - 1 : optionIndex;
    let groupsNumber = 0;

    for (let i = 0; i <= index; i++) {
      if (
        optionsList[i].groupId &&
        groupsList[groupsNumber] &&
        groupsList[groupsNumber].id &&
        optionsList[i].groupId === groupsList[groupsNumber].id
      ) {
        groupsNumber++;
      }
    }

    return groupsNumber;
  }

  _setNextOptionActive() {
    let index = this._activeOptionIndex + 1;
    const options = this._getNavigationOptions();

    if (!options[index]) {
      return;
    }

    while (options[index].disabled) {
      index += 1;

      if (!options[index]) {
        return;
      }
    }

    this._updateActiveOption(options[index], index);
  }

  _setPreviousOptionActive() {
    let index = this._activeOptionIndex - 1;
    const options = this._getNavigationOptions();

    if (!options[index]) {
      return;
    }

    while (options[index].disabled) {
      index -= 1;

      if (!options[index]) {
        return;
      }
    }

    this._updateActiveOption(options[index], index);
  }

  _setFirstOptionActive() {
    const index = 0;
    const options = this._getNavigationOptions();

    this._updateActiveOption(options[index], index);
  }

  _setLastOptionActive() {
    const options = this._getNavigationOptions();
    const index = options.length - 1;

    this._updateActiveOption(options[index], index);
  }

  _updateActiveOption(newActiveOption, index) {
    const currentActiveOption = this._activeOption;

    if (currentActiveOption) {
      currentActiveOption.removeActiveStyles();
    }

    newActiveOption.setActiveStyles();
    this._activeOptionIndex = index;
    this._activeOption = newActiveOption;
  }

  _listenToInputClick() {
    EventHandler.on(this.input, 'click', () => {
      this.open();
    });
  }

  _listenToClearBtnClick() {
    EventHandler.on(this.clearButton, 'click', () => {
      this._handleClear();
    });
  }

  _handleClear() {
    if (this._config.multiple) {
      this._selectionModel._selections = [];
      this._deselectAllOptions(this.options);
      this._updateSelectAllState();
    } else {
      const selected = this._selectionModel.selection;
      this._selectionModel.deselect(selected);
      selected.deselect();
    }
    this._updateInputValue();
    this._updateLabelPosition();
    this._updateClearButtonVisibility();
  }

  _listenToOptionsClick() {
    EventHandler.on(this.optionsWrapper, 'click', (event) => {
      const optionGroupLabel = event.target.classList.contains('select-option-group-label');

      if (optionGroupLabel) {
        return;
      }

      const target =
        event.target.nodeName === 'DIV'
          ? event.target
          : SelectorEngine.closest(event.target, '.select-option');
      const selectAllOption = target.classList.contains('select-all-option');

      if (selectAllOption) {
        this._handleSelectAll();
        return;
      }

      const id = target.dataset.id;
      const option = this.options.find((option) => option.id === id);

      if (option && !option.disabled) {
        this._handleSelection(option);
      }
    });
  }

  _handleSelectAll() {
    const selected = this._selectAllOption.selected;

    if (selected) {
      this._deselectAllOptions(this.options);
      this._selectAllOption.deselect();
    } else {
      this._selectAllOptions(this.options);
      this._selectAllOption.select();
    }

    this._updateInputValue();
    this._updateLabelPosition();
    this._updateClearButtonVisibility();
  }

  _selectAllOptions(options) {
    options.forEach((option) => {
      if (!option.selected && !option.disabled) {
        this._selectionModel.select(option);
        option.select();
      }
    });
  }

  _deselectAllOptions(options) {
    options.forEach((option) => {
      if (option.selected && !option.disabled) {
        this._selectionModel.deselect(option);
        option.deselect();
      }
    });
  }

  _handleSelection(option) {
    if (this._config.multiple) {
      this._handleMultiSelection(option);
      this._updateSelectAllState();
    } else {
      this._handleSingleSelection(option);
    }

    this._updateInputValue();
    this._updateLabelPosition();
    this._updateClearButtonVisibility();
  }

  _handleSingleSelection(option) {
    const currentSelected = this._selectionModel.selections[0];

    if (currentSelected) {
      this._selectionModel.deselect(currentSelected);
      currentSelected.deselect();
      currentSelected.node.setAttribute('selected', false);
      EventHandler.trigger(this._element, EVENT_DESELECT, { value: currentSelected.value });
    }

    this._selectionModel.select(option);
    option.select();
    option.node.setAttribute('selected', true);
    EventHandler.trigger(this._element, EVENT_SELECT, { value: option.value });

    this.close();
    this.input.focus();
  }

  _handleMultiSelection(option) {
    if (option.selected) {
      this._selectionModel.deselect(option);
      option.deselect();
      option.node.setAttribute('selected', false);
      EventHandler.trigger(this._element, EVENT_DESELECT, { value: option.value });
    } else {
      this._selectionModel.select(option);
      option.select();
      option.node.setAttribute('selected', true);
      EventHandler.trigger(this._element, EVENT_SELECT, { value: option.value });
    }
  }

  _updateInputValue() {
    const labels = this._config.multiple ? this._selectionModel.labels : this._selectionModel.label;
    let value;

    if (
      this._config.multiple &&
      this._config.displayedLabels !== -1 &&
      this._selectionModel.selections.length > this._config.displayedLabels
    ) {
      value = `${this._selectionModel.selections.length} options selected`;
    } else {
      value = labels;
    }

    if (value) {
      this.input.value = value;
    } else {
      this.input.value = '';
    }
  }

  _updateLabelPosition() {
    const label = SelectorEngine.findOne('.select-label', this.wrapper);

    if (!label) {
      return;
    }

    if (this.input.value !== '' || this._isOpen) {
      label.classList.add('active');
    } else {
      label.classList.remove('active');
    }
  }

  _updateClearButtonVisibility() {
    if (!this.clearButton) {
      return;
    }

    const hasSelection =
      this._selectionModel.selection || this._selectionModel.selections.length > 0;

    if (hasSelection) {
      this.clearButton.style.display = 'block';
    } else {
      this.clearButton.style.display = 'none';
    }
  }

  _updateSelectAllState() {
    const selectAllSelected = this._selectAllOption.selected;
    const allSelected = this._areAllOptionsSelected();
    if (!allSelected && selectAllSelected) {
      this._selectAllOption.deselect();
    } else if (allSelected && !selectAllSelected) {
      this._selectAllOption.select();
    }
  }

  open() {
    const openEvent = EventHandler.trigger(this._element, EVENT_OPEN);

    if (this._isOpen || openEvent.defaultPrevented) {
      return;
    }

    this._openDropdown();
    this._setFirstActiveOption();
    this._scrollToOption(this._activeOption);

    if (this._config.filter) {
      // We need to wait for popper initialization, otherwise
      // dates container will be focused before popper position
      // update which can change the scroll position on the page
      setTimeout(() => {
        this.filterInput.focus();
      }, 0);

      this._listenToSelectSearch();

      // New listener for dropdown navigation is needed, because
      // we focus search input inside dropdown template, wchich is
      // appended to the body. In this case listener attached to
      // select wrapper won't work
      this._listenToDropdownKeydown();
    }

    this._listenToOptionsClick();
    this._listenToOutsideClick();
    this._listenToWindowResize();

    this._isOpen = true;

    this._updateLabelPosition();
    this._setInputActiveStyles();
  }

  _openDropdown() {
    let template;

    if (this._dropdownTemplateNeedsUpdate) {
      template = this._getDropdownTemplate();
      this._dropdownTemplate = template;
      this._dropdownTemplateNeedsUpdate = false;
    } else {
      template = this._dropdownTemplate;
    }

    this._popper = new Popper(this.input, template, {
      placement: 'bottom-start',
    });
    document.body.appendChild(template);

    // We need to add delay to wait for the popper initialization
    // and position update
    setTimeout(() => {
      this.dropdown.classList.add('open');
    }, 0);
  }

  _setFirstActiveOption() {
    const currentActive = this._activeOption;

    if (currentActive) {
      currentActive.removeActiveStyles();
    }

    const firstSelected = this._config.multiple
      ? this._selectionModel.selections[0]
      : this._selectionModel.selection;

    if (firstSelected) {
      this._activeOption = firstSelected;
      firstSelected.setActiveStyles();
      this._activeOptionIndex = this.options.findIndex((option) => option === firstSelected);
    } else {
      this._activeOption = null;
      this._activeOptionIndex = -1;
    }
  }

  _setInputActiveStyles() {
    this.input.classList.add('focused');
  }

  _listenToWindowResize() {
    EventHandler.on(window, 'resize', this._handleWindowResize.bind(this));
  }

  _handleWindowResize() {
    if (this.dropdownContainer) {
      this.dropdownContainer.style.width = `${this.inputWidth}px`;
    }
  }

  _listenToSelectSearch() {
    this.filterInput.addEventListener('input', (event) => {
      const searchTerm = event.target.value;
      const debounceTime = this._config.filterDebounce;
      this._debounceFilter(searchTerm, debounceTime);
    });
  }

  _debounceFilter(searchTerm, debounceTime) {
    if (this._debounceTimeoutId) {
      clearTimeout(this._debounceTimeoutId);
    }

    this._debounceTimeoutId = setTimeout(() => {
      this._filterOptions(searchTerm);
    }, debounceTime);
  }

  _filterOptions(searchTerm) {
    const filtered = [];

    this._optionsToRender.forEach((option) => {
      const isOptionGroup = option.hasOwnProperty('options');
      const isValidOption =
        !isOptionGroup && option.label.toLowerCase().includes(searchTerm.toLowerCase());
      const group = {};

      if (isOptionGroup) {
        group.label = option.label;
        group.options = this._filter(searchTerm, option.options);

        if (group.options.length > 0) {
          filtered.push(group);
        }
      }

      if (isValidOption) {
        filtered.push(option);
      }
    });

    const optionsWrapperContent =
      SelectorEngine.findOne('.select-options-list') ||
      SelectorEngine.findOne('.select-no-results');

    const hasNoResultsText = this._config.noResultText !== '';
    const hasFilteredOptions = filtered.length !== 0;

    if (hasFilteredOptions) {
      const optionsListTemplate = this._getOptionsListTemplate(filtered);
      this.optionsWrapper.removeChild(optionsWrapperContent);
      this.optionsWrapper.appendChild(optionsListTemplate);
      this._filteredOptionsList = this._getOptionsList(filtered);

      if (this._config.multiple) {
        this._updateSelectAllState();
      }

      this._setFirstActiveOption();
    } else if (!hasFilteredOptions && hasNoResultsText) {
      const noResultsTemplate = this._getNoResultTemplate();
      this.optionsWrapper.innerHTML = noResultsTemplate;
    }
  }

  _getNoResultTemplate() {
    return `<div class="select-no-results">${this._config.noResultText}</div>`;
  }

  _filter(value, options) {
    const filterValue = value.toLowerCase();
    return options.filter((option) => option.label.toLowerCase().includes(filterValue));
  }

  _listenToDropdownKeydown() {
    EventHandler.on(this.dropdown, 'keydown', this._handleOpenKeydown.bind(this));
  }

  _listenToOutsideClick() {
    EventHandler.on(document, 'click', this._handleOutSideClick.bind(this));
  }

  _handleOutSideClick(event) {
    const isSelectContent = this.wrapper && this.wrapper.contains(event.target);
    const isDropdown = event.target === this.dropdownContainer;
    const isDropdownContent =
      this.dropdownContainer && this.dropdownContainer.contains(event.target);

    if (!isSelectContent && !isDropdown && !isDropdownContent) {
      this.close();
    }
  }

  close() {
    const closeEvent = EventHandler.trigger(this._element, EVENT_CLOSE);

    if (!this._isOpen || closeEvent.defaultPrevented) {
      return;
    }

    if (this._config.filter) {
      this._resetFilterState();
    }

    this._removeDropdownEvents();

    this.dropdown.classList.remove('open');
    document.body.removeChild(this.dropdownContainer);
    this._popper.destroy();

    this._isOpen = false;

    setTimeout(() => {
      this.input.classList.remove('focused');
      this._updateLabelPosition();
    }, 0);
  }

  _resetFilterState() {
    this.filterInput.value = '';
    this._filteredOptionsList = null;
    this._dropdownTemplateNeedsUpdate = true;
  }

  _removeDropdownEvents() {
    EventHandler.off(document, 'click', this._handleOutSideClick.bind(this));
    EventHandler.off(this.dropdown, 'keydown', this._handleOpenKeydown.bind(this));
    EventHandler.off(this.optionsWrapper, 'click');
  }

  _addMutationObserver() {
    this._mutationObserver = new MutationObserver(() => {
      this._dropdownTemplateNeedsUpdate = true;
    });

    this._observeMutationObserver();
  }

  _observeMutationObserver() {
    if (!this._mutationObserver) {
      return;
    }

    this._mutationObserver.observe(this._element, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  _disconnectMutationObserver() {
    if (this.mutationObserver) {
      this._mutationObserver.disconnect();
      this._mutationObserver = null;
    }
  }

  _getWrapperTemplate() {
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', this._wrapperId);
    wrapper.classList.add('select-wrapper');

    const formOutline = element('div');
    Manipulator.addClass(formOutline, 'form-outline');

    const input = element('input');
    const role = this._config.filter ? 'combobox' : 'listbox';
    const multiselectable = this._config.multiple ? 'true' : 'false';
    const disabled = this._config.disabled ? 'true' : 'false';
    Manipulator.addClass(input, 'form-control');
    Manipulator.addClass(input, 'select-input');
    input.setAttribute('type', 'text');
    input.setAttribute('role', role);
    input.setAttribute('aria-multiselectable', multiselectable);
    input.setAttribute('aria-disabled', disabled);
    input.setAttribute('aria-haspopup', 'true');
    input.setAttribute('aria-expanded', false);

    if (this._config.disabled) {
      input.setAttribute('disabled', '');
    }

    if (this._config.placeholder !== '') {
      input.setAttribute('placeholder', this._config.placeholder);
    }

    if (!this._config.validation) {
      input.setAttribute('readonly', '');
    }

    if (this._config.validation) {
      input.setAttribute('required', 'true');
      input.setAttribute('aria-required', 'true');
      input.addEventListener('keypress', (event) => event.preventDefault());
    }

    const validFeedback = element('div');
    Manipulator.addClass(validFeedback, 'valid-feedback');
    const validFeedBackText = document.createTextNode(`${this._config.validFeedback}`);
    validFeedback.appendChild(validFeedBackText);

    const invalidFeedback = element('div');
    Manipulator.addClass(invalidFeedback, 'invalid-feedback');
    const invalidFeedBackText = document.createTextNode(`${this._config.invalidFeedback}`);
    invalidFeedback.appendChild(invalidFeedBackText);

    const clearBtn = element('span');
    Manipulator.addClass(clearBtn, 'select-clear-btn');
    const clearBtnText = document.createTextNode('\u2715');
    clearBtn.appendChild(clearBtnText);

    const arrow = element('span');
    Manipulator.addClass(arrow, 'select-arrow');

    formOutline.appendChild(input);

    if (this._label) {
      formOutline.appendChild(this._label);
    }

    if (this._config.validation) {
      formOutline.appendChild(validFeedback);
      formOutline.appendChild(invalidFeedback);
    }

    if (this._config.clearButton) {
      formOutline.appendChild(clearBtn);
    }

    formOutline.appendChild(arrow);

    wrapper.appendChild(formOutline);
    return wrapper;
  }

  _getDropdownTemplate() {
    const dropdownContainer = document.createElement('div');
    dropdownContainer.classList.add('select-dropdown-container');

    dropdownContainer.setAttribute('id', `${this._dropdownContainerId}`);
    dropdownContainer.style.width = `${this.inputWidth}px`;

    const dropdown = document.createElement('div');
    dropdown.setAttribute('tabindex', 0);
    dropdown.classList.add('select-dropdown');

    const optionsWrapper = element('div');
    Manipulator.addClass(optionsWrapper, 'select-options-wrapper');
    optionsWrapper.style.maxHeight = `${this._dropdownHeight}px`;

    const optionsList = this._getOptionsListTemplate(this._optionsToRender);

    optionsWrapper.appendChild(optionsList);

    if (this._config.filter) {
      dropdown.appendChild(this._getFilterTemplate());
    }

    dropdown.appendChild(optionsWrapper);

    if (this._customContent) {
      dropdown.appendChild(this._customContent);
    }

    dropdownContainer.appendChild(dropdown);

    return dropdownContainer;
  }

  _getOptionsListTemplate(options) {
    const optionsList = element('div');
    Manipulator.addClass(optionsList, 'select-options-list');

    const optionsNodes = this._createOptionsNodes(options);

    optionsNodes.forEach((node) => {
      optionsList.appendChild(node);
    });

    return optionsList;
  }

  _getFilterTemplate() {
    const inputGroup = element('div');
    Manipulator.addClass(inputGroup, 'input-group');
    const input = element('input');
    Manipulator.addClass(input, 'form-control');
    Manipulator.addClass(input, 'select-filter-input');
    input.placeholder = 'Search...';
    input.setAttribute('role', 'searchbox');
    input.setAttribute('type', 'text');

    inputGroup.appendChild(input);

    return inputGroup;
  }

  _createOptionsNodes(options) {
    const nodes = [];

    if (this._config.multiple) {
      const selectAllNode = this._createSelectAllOptionTemplate(this._selectAllOption);
      nodes.push(selectAllNode);
    }

    options.forEach((option) => {
      const isOptionGroup = option.hasOwnProperty('options');
      if (isOptionGroup) {
        const group = this._createOptionGroupTemplate(option);
        nodes.push(group);
      } else {
        nodes.push(this._createOptionTemplate(option));
      }
    });

    return nodes;
  }

  _createSelectAllOptionTemplate(option) {
    const isSelected = this._areAllOptionsSelected();
    const optionNode = element('div');
    Manipulator.addClass(optionNode, 'select-option');
    Manipulator.addClass(optionNode, 'select-all-option');
    optionNode.setAttribute('role', 'option');
    optionNode.setAttribute('aria-selected', isSelected);

    if (isSelected) {
      Manipulator.addClass(optionNode, 'selected');
    }

    optionNode.appendChild(this._getOptionContentTemplate(option));
    option.setNode(optionNode);

    return optionNode;
  }

  _createOptionTemplate(option) {
    if (option.node) {
      return option.node;
    }

    const optionNode = element('div');
    Manipulator.addClass(optionNode, 'select-option');
    Manipulator.setDataAttribute(optionNode, 'id', option.id);
    optionNode.setAttribute('role', 'option');
    optionNode.setAttribute('aria-selected', option.selected);
    optionNode.setAttribute('aria-disabled', option.disabled);

    const isSelected = this.selections.find((selection) => selection.value === option.value);

    if (isSelected) {
      Manipulator.addClass(optionNode, 'selected');
    }

    if (option.disabled) {
      Manipulator.addClass(optionNode, 'disabled');
    }

    optionNode.appendChild(this._getOptionContentTemplate(option));

    if (option.icon) {
      optionNode.appendChild(this._getOptionIconTemplate(option));
    }

    option.setNode(optionNode);

    return optionNode;
  }

  _getOptionContentTemplate(option) {
    const content = element('span');
    Manipulator.addClass(content, 'select-option-text');

    const label = document.createTextNode(option.label);

    if (this._config.multiple) {
      content.appendChild(this._getCheckboxTemplate(option));
    }

    content.appendChild(label);

    if (option.secondaryText) {
      content.appendChild(this._getSecondaryTextTemplate(option.secondaryText));
    }

    return content;
  }

  _getSecondaryTextTemplate(text) {
    const span = element('span');
    Manipulator.addClass(span, 'select-option-secondary-text');
    const textContent = document.createTextNode(text);
    span.appendChild(textContent);
    return span;
  }

  _getCheckboxTemplate(option) {
    const checkbox = element('input');
    checkbox.setAttribute('type', 'checkbox');
    Manipulator.addClass(checkbox, 'form-check-input');

    const label = element('label');

    if (option.selected) {
      checkbox.setAttribute('checked', true);
    }

    if (option.disabled) {
      checkbox.setAttribute('disabled', true);
    }

    checkbox.appendChild(label);
    return checkbox;
  }

  _getOptionIconTemplate(option) {
    const container = element('span');
    Manipulator.addClass(container, 'select-option-icon-container');
    const image = element('img');
    Manipulator.addClass(image, 'select-option-icon');
    Manipulator.addClass(image, 'rounded-circle');
    image.src = option.icon;

    container.appendChild(image);
    return container;
  }

  _createOptionGroupTemplate(optionGroup) {
    const group = element('div');
    Manipulator.addClass(group, 'select-option-group');
    group.setAttribute('role', 'group');
    group.setAttribute('id', optionGroup.id);

    const label = element('label');
    Manipulator.addClass(label, 'select-option-group-label');
    label.setAttribute('for', optionGroup.id);
    label.textContent = optionGroup.label;

    group.appendChild(label);

    optionGroup.options.forEach((option) => {
      group.appendChild(this._createOptionTemplate(option));
    });

    return group;
  }

  _createSelectAllOption() {
    const selected = this._areAllOptionsSelected();
    return new SelectOption(
      'option-select-all',
      null,
      true,
      'select-all',
      'Select all',
      selected,
      false,
      null,
      null,
      null
    );
  }

  _areAllOptionsSelected() {
    return this.options
      .filter((option) => !option.disabled)
      .every((option) => {
        return option.selected;
      });
  }

  _renderMaterialWrapper() {
    const template = this._getWrapperTemplate();
    this._element.parentNode.insertBefore(template, this._element);
    this._element.classList.add('initialized');
    template.appendChild(this._element);
    const inputWrapper = SelectorEngine.findOne('.form-outline', this.wrapper);
    this._input = new Input(inputWrapper);
    this._input.init();
  }

  dispose() {
    if (this._isOpen) {
      this.close();
    }

    this._disconnectMutationObserver();

    this._destroyTemplate();

    Data.removeData(this._element, DATA_KEY);
    this._element = null;
  }

  _destroyTemplate() {
    const wrapperParent = this.wrapper.parentNode;
    wrapperParent.appendChild(this._element);
    this._element.classList.remove('initialized');
    wrapperParent.removeChild(this.wrapper);
  }

  static jQueryInterface(config, options) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY);
      const _config = typeof config === 'object' && config;

      if (!data && /dispose/.test(config)) {
        return;
      }

      if (!data) {
        data = new Select(this, _config);
      }

      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config](options);
      }
    });
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY);
  }
}

export default Select;

const $ = getjQuery();

SelectorEngine.find(SELECTOR_SELECT).forEach((select) => {
  let instance = Select.getInstance(select);
  if (!instance) {
    instance = new Select(select);
  }
});

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .timepicker to jQuery only if jQuery is present
 */

if ($) {
  const JQUERY_NO_CONFLICT = $.fn[NAME];
  $.fn[NAME] = Select.jQueryInterface;
  $.fn[NAME].Constructor = Select;
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return Select.jQueryInterface;
  };
}
